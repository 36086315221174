import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { cardIcon1, cardIcon2, cardIcon3, cardIcon4 } from '../../../pages/imageLoader/images';
import { Else, If, Then } from 'react-if';
import { findRankImage } from '../../imageLoader/images';

const Card = ({ sortedAccounts, setPopUpData, loadingAccounts }) => {
  const navigate = useNavigate();

  const itemsPerPage = 6;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);

  const handleShowMore = () => {
    // Increase the number of visible items by 6
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  function formatNumber(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <>
      <If condition={loadingAccounts}>
        <Then>
          <div className="d-flex">
            <div
              className="spinner-border m-auto text-primary mt-4"
              style={{
                width: '2rem',
                height: '2rem',
                borderWidth: '0.2em',
              }}
            >
              <span className="sr-only"></span>
            </div>
          </div>
        </Then>
        <Else>
          <If condition={!sortedAccounts || sortedAccounts?.length === 0}>
            <Then>
              <div className="no-accs m-auto">
                <div className="text-center fw-bold mb-1" style={{ fontSize: '1rem' }}>
                  Can't find your dream account?
                </div>
                <div className="text-center">
                  Open a Ticket in our{' '}
                  <a
                    className="fw-bold"
                    style={{ color: '#171630', textDecoration: 'underline' }}
                    href="https://discord.com/invite/Boostopia"
                    target="blank"
                  >
                    Discord server
                  </a>{' '}
                  and we will add it.
                </div>
              </div>
            </Then>
            <Else>
              <div className="row">
                {sortedAccounts?.map(
                  (accountItem, index) =>
                    index < visibleItems && (
                      <div key={index} className="col-lg-4 col-md-4 col-sm-6">
                        <div className="shopcard fs-6">
                          <div className="shopcard-img">
                            <img
                              src={
                                accountItem.gallery && accountItem.gallery?.length > 0
                                  ? `${accountItem.gallery[0]}` // use s3 bucket link to access image
                                  : // ? `${process.env.REACT_APP_API_ENDPOINT}${accountItem.gallery[0]}`
                                    findRankImage(
                                      accountItem.accCheckerData?.soloTier?.toLowerCase(),
                                    ) || '/noimage.jpg'
                              }
                              onError={({ currentTarget }) => {
                                if (currentTarget.src !== `/noimage.jpg`) {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = `/noimage.jpg`;
                                }
                              }}
                              alt="Account Showcase"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalAcount"
                              onClick={() => {
                                setPopUpData(accountItem);
                                navigate('/accounts');
                              }}
                              style={{
                                cursor: 'pointer',
                                border: 'solid 1px transparent',
                                transition: 'border 0.3s ease',
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.border = 'solid 1px #FFD957')
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.border = 'solid 1px transparent')
                              }
                            />
                          </div>
                          <h2
                            className="mb-3 fw-bold"
                            style={{
                              fontSize: '1.125rem',
                              fontFamily: 'Poppins,sans-serif',
                              lineHeight: '1.25',
                            }}
                          >
                            {accountItem.title
                              ? accountItem.title + ' • ' + accountItem.accCheckerData.region
                              : 'Default Name'}
                          </h2>
                          <div className="row row-info g-0 m-0" style={{ fontSize: '0.875rem' }}>
                            <div className="col-12">
                              <img src={cardIcon4} alt="Champs" />
                              <span
                                className={
                                  accountItem.accCheckerData?.ownedChampionsCount > 100
                                    ? 'font-weight-600'
                                    : ''
                                }
                              >
                                {accountItem.accCheckerData?.ownedChampionsCount} Champs
                              </span>
                            </div>
                            <div className="col-12">
                              <img src={cardIcon3} alt="Skins" />
                              <span
                                className={
                                  accountItem.accCheckerData?.ownedSkinsCount > 100
                                    ? 'font-weight-600'
                                    : ''
                                }
                              >
                                {accountItem.accCheckerData?.ownedSkinsCount} Skins
                              </span>
                            </div>
                            <div className="col-12">
                              <span
                                className={
                                  accountItem.accCheckerData.be > 25000 ? 'font-weight-600' : ''
                                }
                              >
                                <img src={cardIcon1} alt="Blue Essence" />
                                {formatNumber(accountItem.accCheckerData.be)} BE
                              </span>
                            </div>
                            {/*<div className="col-12">
                              <img src={cardIcon2} alt="Riot Points" />
                              {accountItem.accCheckerData.rp} RP
                                </div>*/}
                            <div className="col-12">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#FFF6C8"
                                className="ms-1 me-1 bi bi-person-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                              </svg>
                              <span
                                className={
                                  accountItem.accCheckerData.summonerLevel > 100
                                    ? 'font-weight-600 ms-1'
                                    : 'ms-1'
                                }
                              >
                                {accountItem.accCheckerData.summonerLevel} Level
                              </span>
                            </div>
                          </div>

                          <hr className="my-2" />

                          <div className="row">
                            <div className="col price mt-auto" style={{ fontSize: '0.875rem' }}>
                              <span>Price</span>
                              <div className="fs-5">
                                <span
                                  style={{
                                    textDecoration: 'line-through',
                                    color: '#aaa',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {accountItem.price?.toLocaleString()}{' '}
                                  {accountItem.currency === 'eur' ? '€' : '$'}
                                </span>
                                <br />
                                <span style={{ color: '#00B67A', fontWeight: 'bold' }}>
                                  {(accountItem.price - accountItem.price * 0.25)?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )}{' '}
                                  {accountItem.currency === 'eur' ? '€' : '$'}
                                </span>
                              </div>
                            </div>
                            <div className="col m-auto">
                              <button
                                type="button"
                                className="btn btn-primary fw-bold"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalAcount"
                                onClick={() => {
                                  setPopUpData(accountItem);
                                  navigate('/accounts');
                                }}
                              >
                                More info
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                )}
                {visibleItems < sortedAccounts?.length && (
                  <div className="row">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary btn-show fw-bold mt-3"
                        onClick={handleShowMore}
                      >
                        Show more
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Else>
          </If>
        </Else>
      </If>
    </>
  );
};

export default Card;
